export const BLACK = '#2d2d2d';
export const DARK_GREY = 'rgba(45, 45, 45, 0.64)';
export const RED = '#ff5858';
export const WHITE = '#ffffff';
export const ORANGE = '#ff8200';
export const LIGHT_ORANGE = '#ff9f3c';
export const VERY_LIGHT_ORANGE = '#fff5eb';
export const GREY = '#e8e8e8';
export const LIGHT_GREY = '#f2f2f2';
export const VERY_LIGHT_GREY = '#fafafa';
export const PALE_GREY = '#f7f8f9';
