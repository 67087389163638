import React from 'react';
import { StyleSheet, ViewProps, View } from 'react-native';

import Cell from './Cell';
import convertToTitleCase from '../../helpers/convertToTitleCase';
import { TableStructure } from './types';
import { ROW_HEIGHT } from '../../constants/table';

type Props = ViewProps & {
  autoNumber?: boolean;
  structure: TableStructure;
  rowHeight?: number;
};

export default function TableHeader(props: Props) {
  let { autoNumber, structure, style, rowHeight } = props;
  let height = rowHeight || ROW_HEIGHT;
  let headerFields = Object.keys(structure);

  return (
    <View style={[styles.row, style]}>
      {autoNumber && <Cell containerStyle={styles.cell}>No.</Cell>}
      {headerFields.map((column, idx) => {
        let {
          alias,
          grid,
          style: {
            header: { containerStyle, contentStyle },
          },
        } = structure[column];

        let content =
          alias || alias === ''
            ? convertToTitleCase(alias)
            : convertToTitleCase(column);

        return (
          <Cell
            key={idx}
            height={height}
            containerStyle={[styles.cell, containerStyle]}
            contentStyle={[styles.content, contentStyle]}
            grid={grid}
          >
            {content}
          </Cell>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  cell: {
    backgroundColor: 'transparent',
  },
  content: {
    fontWeight: '900',
  },
});
