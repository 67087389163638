import React, { useState } from 'react';
import {
  TouchableOpacity,
  StyleSheet,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { Menu } from 'react-native-paper';
import { MaterialIcons } from '@expo/vector-icons';

import { Text } from '../core-ui';
import { ORANGE, GREY, WHITE } from '../constants/colors';

type Props = {
  selectedValue: number;
  optionsValue: Array<number>;
  onOptionPress: (value: number) => void;
  containerStyle?: StyleProp<ViewStyle>;
  disabled: boolean;
};

export default function Dropdown(props: Props) {
  let {
    selectedValue,
    optionsValue,
    onOptionPress,
    containerStyle = {},
    disabled,
  } = props;
  let [isVisible, setIsVisible] = useState(false);

  let openMenu = () => {
    setIsVisible(true);
  };

  let closeMenu = () => {
    setIsVisible(false);
  };

  let onItemPress = (value: typeof selectedValue) => {
    onOptionPress(value);
    setIsVisible(false);
  };

  let renderAnchor = () => {
    let { selectedValue } = props;
    return (
      <TouchableOpacity
        style={[styles.anchor, containerStyle]}
        onPress={openMenu}
        disabled={disabled}
      >
        <Text style={styles.selectedValue}>{selectedValue}</Text>
        <MaterialIcons name="keyboard-arrow-down" size={24} color={ORANGE} />
      </TouchableOpacity>
    );
  };

  return (
    <Menu
      visible={isVisible}
      style={styles.optionsContainer}
      onDismiss={closeMenu}
      anchor={renderAnchor()}
    >
      {optionsValue.map((optionValue) => (
        <TouchableOpacity
          key={optionValue}
          style={styles.optionContainer}
          onPress={() => onItemPress(optionValue)}
        >
          <Text>{optionValue}</Text>
        </TouchableOpacity>
      ))}
    </Menu>
  );
}

const styles = StyleSheet.create({
  anchor: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: GREY,
    width: 84,
    backgroundColor: WHITE,
  },
  selectedValue: {
    // NOTE : this one is needed because the MaterialIcons has its own padding
    marginLeft: 6,
  },
  optionsContainer: {
    width: 84,
    marginTop: 40,
    marginLeft: 16,
  },
  optionContainer: {
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
