import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { Text, TextInput } from '../core-ui';
import { WHITE, GREY, BLACK } from '../constants/colors';
import { TEXT_INPUT_RADIUS } from '../constants/textinput';

type Props = {
  searchText: string;
  placeholder?: string;
  onCreateLinkButtonPress?: () => void;
  onFilterPress?: () => void;
  onSearchPress: () => void;
  setSearchText: (text: string) => void;
};

export default function SearchBar(props: Props) {
  let {
    searchText,
    placeholder,
    onCreateLinkButtonPress,
    onFilterPress,
    onSearchPress,
    setSearchText,
  } = props;
  let [prevText, setPrevText] = useState('');

  let onButtonPress = () => {
    if (searchText.trim().length) {
      setSearchText('');
    } else {
      onSearchPress();
    }
  };

  return onCreateLinkButtonPress && onFilterPress ? (
    <View style={styles.searchAndCreate}>
      <View style={styles.filterAndSearch}>
        <View style={styles.filterContainer}>
          <TouchableOpacity style={styles.filter} onPress={onFilterPress}>
            <Text style={styles.filterText}>Filter by Tags</Text>
            <MaterialIcons name="arrow-drop-down" size={22} color={BLACK} />
          </TouchableOpacity>
        </View>
        <TextInput
          value={searchText}
          placeholder={placeholder}
          containerStyle={styles.textInputContainer}
          inputStyle={{ borderColor: 'transparent' }}
          onChangeText={(text) => {
            setSearchText(text);
          }}
          onKeyPress={(e) => {
            if (e.nativeEvent.key === 'Enter') {
              if (prevText.trim() !== searchText.trim()) {
                setPrevText(searchText);
                onSearchPress();
              }
            }
          }}
        />
        <TouchableOpacity style={styles.searchButton} onPress={onButtonPress}>
          {!searchText.trim().length ? (
            <MaterialIcons name="search" size={22} color={GREY} />
          ) : (
            <MaterialIcons name="close" size={22} color={GREY} />
          )}
        </TouchableOpacity>
      </View>
      <View style={styles.createLinkContainer}>
        <TouchableOpacity
          style={styles.createLinkButton}
          onPress={onCreateLinkButtonPress}
        >
          <MaterialIcons name="add" size={24} color={BLACK} />
          <Text style={styles.createLinkText}>Create New Link</Text>
        </TouchableOpacity>
      </View>
    </View>
  ) : (
    <View style={styles.searchAndCreate2}>
      <View style={styles.filterAndSearch2}>
        <TextInput
          value={searchText}
          placeholder={placeholder}
          containerStyle={styles.textInputContainer2}
          inputStyle={{ borderColor: 'transparent' }}
          onChangeText={(text) => {
            setSearchText(text);
          }}
          onKeyPress={(e) => {
            if (e.nativeEvent.key === 'Enter') {
              if (prevText.trim() !== searchText.trim()) {
                setPrevText(searchText);
                onSearchPress();
              }
            }
          }}
        />
        <TouchableOpacity style={styles.searchButton2} onPress={onButtonPress}>
          {!searchText.trim().length ? (
            <MaterialIcons name="search" size={22} color={GREY} />
          ) : (
            <MaterialIcons name="close" size={22} color={GREY} />
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  searchAndCreate: {
    flexDirection: 'row',
    width: '60%',
    borderWidth: 1,
    borderColor: 'transparent',
    height: 42,
  },
  searchAndCreate2: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 1,
    borderColor: GREY,
    height: 40,
    justifyContent: 'space-between',
    marginBottom: 8,
    borderRadius: 4,
  },
  filterAndSearch: {
    flex: 1,
    backgroundColor: WHITE,
    height: '100%',
    borderRadius: 4,
    flexDirection: 'row',
    shadowColor: BLACK,
    shadowOpacity: 0.2,
    shadowRadius: 12,
    shadowOffset: {
      height: 4,
      width: 0,
    },
  },
  filterAndSearch2: {
    width: '74%',
    backgroundColor: WHITE,
    height: '100%',
    borderRadius: 4,
    flexDirection: 'row',
  },
  filterContainer: {
    width: '25%',
    backgroundColor: 'WHITE',
    height: '100%',
    borderRightWidth: 1,
    borderRightColor: GREY,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  filter: {
    top: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  filterText: {
    paddingTop: 3,
  },
  createLinkContainer: {
    marginLeft: 20,
    backgroundColor: WHITE,
    borderRadius: 4,
    shadowOpacity: 0.2,
    shadowRadius: 12,
    shadowOffset: {
      height: 4,
      width: 0,
    },
  },
  createLinkButton: {
    flexDirection: 'row',
    paddingTop: 8,
    paddingLeft: 30,
    height: 42,
    shadowColor: BLACK,
  },
  createLinkText: {
    paddingTop: 4,
    marginRight: 30,
    marginLeft: 13,
    fontWeight: 'bold',
  },
  searchButton: {
    top: 8,
    right: 40,
  },
  searchButton2: {
    top: 8,
    left: '6%',
  },
  textInputContainer: {
    borderColor: GREY,
    borderRadius: TEXT_INPUT_RADIUS,
    width: '75%',
  },
  textInputContainer2: {
    borderColor: GREY,
    borderRadius: TEXT_INPUT_RADIUS,
    width: '120%',
  },
});
