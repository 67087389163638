import React, { useState, Fragment } from 'react';
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { Menu } from 'react-native-paper';
import { IconButton, Text } from '../core-ui';

import { GREY } from '../constants/colors';

type MenuItemStructure = {
  title: string;
  disabled?: boolean;
  style?: StyleProp<TextStyle>;
  onItemPress: () => void;
};

type Props = {
  structures: Array<MenuItemStructure>;
  anchorStyle?: StyleProp<ViewStyle>;
};

export default function Popover(props: Props) {
  const [showPopover, togglePopover] = useState(false);

  let { structures, anchorStyle } = props;

  let _onTogglePress = () => {
    if (showPopover) {
      togglePopover(false);
    } else {
      togglePopover(true);
    }
  };

  let handleItemPressed = (onItemPress: () => void) => {
    togglePopover(false);
    onItemPress();
  };

  return (
    <Fragment>
      <Menu
        style={styles.options}
        visible={showPopover}
        onDismiss={_onTogglePress}
        anchor={
          <IconButton
            icon="more-vert"
            color={GREY}
            onPress={_onTogglePress}
            style={anchorStyle}
          />
        }
      >
        {structures.map(({ title, disabled, onItemPress, style }, index) => (
          <TouchableOpacity
            key={index}
            style={styles.optionContainer}
            onPress={() => handleItemPressed(onItemPress)}
            disabled={disabled}
          >
            <Text style={style}>{title}</Text>
          </TouchableOpacity>
        ))}
      </Menu>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  options: { width: 160, marginTop: 30, marginLeft: 23 },
  optionContainer: {
    height: 42,
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
});
