import React, { useState, Dispatch, SetStateAction } from 'react';
import {
  TextInput as BaseTextInput,
  View,
  TextInputProps,
  TextStyle,
  ViewStyle,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import Text from '../core-ui/Text';
import { Menu } from 'react-native-paper';
import { GREY, PALE_GREY, RED, ORANGE, BLACK } from '../constants/colors';
import { TEXT_INPUT_HEIGHT, TEXT_INPUT_RADIUS } from '../constants/textinput';
import { MaterialIcons } from '@expo/vector-icons';

type Props = TextInputProps & {
  value: string;
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  inputStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  textInputContainerStyle?: StyleProp<ViewStyle>;
  selectedValue?: string;
  onPressTags?: Dispatch<SetStateAction<string>>;
  tagsValue?: Array<Array<string>>;
  groupText?: boolean;
  optionsValue?: Array<string>;
  onOptionPress?: (value: string) => void;
  containerStyleDropdown?: StyleProp<ViewStyle>;
  disabled?: boolean;
  maxInputLimit?: number;
};

export default function Textinput(props: Props) {
  let {
    value,
    label,
    isError,
    errorMessage,
    inputStyle,
    containerStyle,
    textInputContainerStyle,
    selectedValue,
    groupText,
    optionsValue = [],
    onOptionPress,
    containerStyleDropdown = {},
    disabled,
    maxInputLimit,
    tagsValue,
    onPressTags,
    ...otherProps
  } = props;

  let mixedInputStyle = [styles.defaultInputStyle, inputStyle];
  let [isVisible, setIsVisible] = useState(false);

  let openMenu = () => {
    setIsVisible(true);
  };

  let closeMenu = () => {
    setIsVisible(false);
  };

  let onItemPress = (value: typeof selectedValue) => {
    if (value && onOptionPress) {
      onOptionPress(value);
      setIsVisible(false);
    }
  };

  let renderAnchor = () => {
    return (
      <TouchableOpacity
        accessible={false}
        style={[styles.anchor, containerStyleDropdown]}
        onPress={openMenu}
        disabled={disabled}
        {...otherProps}
      >
        {!tagsValue ? (
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.selectedValue}>{selectedValue}</Text>
            <MaterialIcons
              name="keyboard-arrow-down"
              size={22}
              color={ORANGE}
            />
          </View>
        ) : (
          <View style={{ flexDirection: 'row' }}>
            <View
              style={[
                styles.selectedColorContainer,
                {
                  backgroundColor: selectedValue,
                },
              ]}
            />
            <MaterialIcons name="arrow-drop-down" size={22} color={BLACK} />
          </View>
        )}
      </TouchableOpacity>
    );
  };

  return (
    <View style={[styles.root, containerStyle]}>
      <Text style={styles.labelText}>{label}</Text>
      <View style={[styles.textInputContainer, textInputContainerStyle]}>
        {groupText && optionsValue.length === 1 ? (
          <Text style={styles.inputGroupStyle}>{optionsValue[0]}</Text>
        ) : groupText ? (
          !tagsValue ? (
            <Menu
              visible={isVisible}
              style={styles.optionsContainer}
              onDismiss={closeMenu}
              anchor={renderAnchor()}
            >
              {optionsValue &&
                optionsValue.map((optionValue) => (
                  <TouchableOpacity
                    key={optionValue}
                    style={styles.optionContainer}
                    onPress={() => onItemPress(optionValue)}
                  >
                    <Text>{optionValue}</Text>
                  </TouchableOpacity>
                ))}
            </Menu>
          ) : (
            <Menu
              visible={isVisible}
              style={styles.optionContainer2}
              onDismiss={closeMenu}
              anchor={renderAnchor()}
            >
              {tagsValue &&
                tagsValue.map((optionValue, index) => {
                  return (
                    <View key={index} style={styles.tagDropdown}>
                      {optionValue.map((color) => {
                        return (
                          <TouchableOpacity
                            key={color}
                            style={[
                              styles.iconMenu,
                              { backgroundColor: color },
                            ]}
                            onPress={() => {
                              onPressTags && onPressTags(color);
                            }}
                          />
                        );
                      })}
                    </View>
                  );
                })}
            </Menu>
          )
        ) : null}
        <BaseTextInput {...otherProps} style={mixedInputStyle} value={value} />
      </View>
      <View style={styles.bottomTextInput}>
        {isError ? (
          <Text style={styles.errorText} size="extraSmall">
            {errorMessage}
          </Text>
        ) : (
          <View style={styles.emptyView} />
        )}
        {maxInputLimit ? (
          <Text
            style={value.length >= maxInputLimit ? styles.limitString : {}}
          >{`${value.length}/${maxInputLimit}`}</Text>
        ) : (
          <View style={styles.emptyView} />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    justifyContent: 'center',
    marginTop: 10,
  },
  defaultInputStyle: {
    flex: 1,
    height: TEXT_INPUT_HEIGHT,
    paddingHorizontal: 12,
    paddingVertical: 13,
  },
  textInputContainer: {
    borderColor: GREY,
    borderRadius: TEXT_INPUT_RADIUS,
    alignContent: 'stretch',
    flexDirection: 'row',
  },
  inputGroupStyle: {
    paddingVertical: 12,
    paddingHorizontal: 13,
    backgroundColor: PALE_GREY,
    borderRightColor: GREY,
    borderRightWidth: StyleSheet.hairlineWidth,
  },
  errorText: {
    color: RED,
  },
  labelText: {
    marginBottom: 8,
    fontSize: 12,
    marginLeft: 3,
  },
  emptyView: {
    height: 17,
  },
  optionsContainer: {
    width: 78,
    marginTop: 40,
    marginLeft: -1,
  },
  optionContainer: {
    height: 36,
    justifyContent: 'center',
    paddingHorizontal: 6,
  },
  optionContainer2: {
    marginTop: 43,
  },
  anchor: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    right: 7,
    height: 40,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderColor: GREY,
    backgroundColor: PALE_GREY,
  },
  selectedValue: {
    marginLeft: 6,
  },
  selectedColorContainer: {
    width: 20,
    height: 20,
    borderRadius: 2,
    marginLeft: 12,
    marginRight: 8,
  },
  limitString: {
    color: RED,
  },
  bottomTextInput: {
    marginTop: 4,
    marginHorizontal: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 17,
  },
  iconMenu: {
    width: 20,
    height: 20,
    borderRadius: 2,
    marginLeft: 5,
    marginRight: 16,
  },
  tagDropdown: {
    flexDirection: 'row',
    paddingVertical: 8,
    paddingHorizontal: 8,
    left: 6,
  },
});
