import React, { ReactNode, Fragment, createContext, useState } from 'react';
import { Modal } from 'react-native-paper';
import { StyleSheet } from 'react-native';

type ShowModalFn = (content: ReactNode, allowDismiss?: boolean) => void;

let defaultContextValue = {
  isVisible: false,
  showModal: ((_content, _allowDismiss) => {
    //
  }) as ShowModalFn,
  hideModal: () => {
    // do something
  },
};

type GlobalMContext = typeof defaultContextValue;

export let GlobalMContext = createContext<GlobalMContext>(defaultContextValue);

type Props = {
  children: ReactNode;
};

export default function GlobalModal(props: Props) {
  let [allowDismiss, setAllowDismiss] = useState(false);
  let [visible, setVisible] = useState(false);
  let [content, setContent] = useState<ReactNode>(null);

  let showModal: ShowModalFn = (content, allowDismiss = false) => {
    setContent(content);
    setVisible(true);
    setAllowDismiss(allowDismiss);
  };

  let hideModal = () => {
    setContent(null);
    setVisible(false);
  };

  let { children } = props;
  return (
    <Fragment>
      <GlobalMContext.Provider
        value={{
          isVisible: visible,
          showModal: showModal,
          hideModal: hideModal,
        }}
      >
        {children}
      </GlobalMContext.Provider>
      <Modal
        visible={visible}
        onDismiss={allowDismiss ? hideModal : undefined}
        contentContainerStyle={styles.container}
      >
        {content}
      </Modal>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
  },
});
