import React, { useContext } from 'react';
import {
  AsyncStorage,
  ImageBackground,
  Image,
  View,
  StyleSheet,
} from 'react-native';
import firebase from 'firebase';

import { Button, Card, Text, GlobalSnackbar } from '../core-ui';

import {
  backgroundImage,
  google,
  welcomeLogo,
  kfoxLogo,
} from '../../assets/images';
import { VERY_LIGHT_GREY, WHITE } from '../constants/colors';
import { checkEmail } from '../helpers';
import { useMutation } from 'react-fetching-library';
import { authActionCreator } from '../query/auth';

type Props = {
  setIsLogin: (state: boolean) => void;
};

export default function LoginScene(props: Props) {
  let snackbarContext = useContext(GlobalSnackbar);

  let { mutate: authUser } = useMutation(authActionCreator);

  let handleLoginBtn = async () => {
    const { setIsLogin } = props;
    try {
      let provider = new firebase.auth.GoogleAuthProvider();
      let signIn = await firebase.auth().signInWithPopup(provider);
      if (
        signIn &&
        signIn.user &&
        signIn.user.email &&
        signIn.user.uid &&
        signIn.user.displayName
      ) {
        let checkedEmail = checkEmail(signIn.user.email);
        if (checkedEmail) {
          let credential = await firebase.auth().currentUser;
          let idToken = credential && (await credential.getIdToken());

          let authResponse = await authUser(idToken || '');

          if (authResponse.payload.status === 'success') {
            await AsyncStorage.setItem(
              'credential',
              authResponse.payload.data.token,
            );
            setIsLogin(true);
          } else {
            await firebase.auth().signOut();
            snackbarContext.show(authResponse.payload.message, 3000);
            setIsLogin(false);
          }
        } else {
          await firebase.auth().signOut();
          snackbarContext.show(
            'Please Sign In With Your KodeFox Account',
            3000,
          );
          setIsLogin(false);
        }
      }
    } catch (error) {
      snackbarContext.show(error && error.message, 3000);
    }
  };

  return (
    <ImageBackground source={backgroundImage} style={styles.backgroundImage}>
      <View>
        <Image source={kfoxLogo} style={styles.kodefoxLogo} />
        <Card.Content style={styles.cardContainer}>
          <Image source={welcomeLogo} style={styles.welcomeLogo} />
          <View style={styles.textContainer}>
            <Text style={styles.titleText}>Welcome to Kfox.io!</Text>
            <Text style={styles.contentText}>
              To start use and create a short link, please sign in with your
              KodeFox email address.
            </Text>
            <Button style={styles.loginButton} onPress={handleLoginBtn}>
              <Image source={google} style={styles.googleLogo} />
              <View style={styles.googleSignInText}>
                <Text color={VERY_LIGHT_GREY} weight="bold">
                  Continue with Google
                </Text>
              </View>
            </Button>
          </View>
        </Card.Content>
      </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: 'row',
    backgroundColor: WHITE,
    maxWidth: 720,
    padding: 36,
    borderRadius: 4,
  },
  loginButton: { alignSelf: 'flex-start', paddingTop: 3, marginLeft: -1 },
  googleSignInText: { bottom: 4 },
  googleLogo: { width: 20, height: 20, marginRight: 12 },
  welcomeLogo: { width: 161, height: 157.2 },
  textContainer: { marginLeft: 32, flex: 1 },
  titleText: { fontSize: 18, fontWeight: 'bold' },
  contentText: {
    marginVertical: 16,
    marginTop: 16,
    marginBottom: 24,
    lineHeight: 24,
  },
  backgroundImage: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  kodefoxLogo: {
    width: 55,
    height: 40,
    position: 'absolute',
    alignSelf: 'center',
    top: -80,
  },
});
