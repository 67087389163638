import React, { useState, useEffect, useContext } from 'react';
import { AsyncStorage } from 'react-native';

import { HomeScene, LoginScene } from '../scenes';
import { GlobalSnackbar } from '../core-ui';

export default function MainRoute() {
  let [isLogin, setIsLogin] = useState(false);
  let snackbarContext = useContext(GlobalSnackbar);

  useEffect(() => {
    try {
      AsyncStorage.getItem('credential').then((result) => {
        if (result) {
          setIsLogin(true);
        } else {
          setIsLogin(false);
        }
      });
    } catch (error) {
      snackbarContext.show(error && error.message, 3000);
    }
  }, []);

  let setLoginState = (state: boolean) => {
    setIsLogin(state);
  };

  return isLogin ? (
    <HomeScene setIsLogin={setLoginState} />
  ) : (
      <LoginScene setIsLogin={setLoginState} />
    );
}
