import React, { ReactElement, useCallback, useState } from 'react';
import {
  LayoutRectangle,
  StyleSheet,
  StyleProp,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import { Portal } from 'react-native-paper';

import { Text } from '../../core-ui';

import { BLACK, WHITE } from '../../constants/colors';
import { Placement } from './types';

const DEFAULT_LAYOUT: Readonly<LayoutRectangle> = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

type Props = ViewProps & {
  renderAnchor: () => ReactElement;
  placement?: Placement;
  visible: boolean;
  content: string;
};

export default function Tooltip(props: Props) {
  let {
    visible,
    renderAnchor,
    content,
    placement = 'left' as Placement,
    style,
    ...otherProps
  } = props;

  let [anchorLayout, setAnchorLayout] = useState<LayoutRectangle>(
    DEFAULT_LAYOUT,
  );

  let measureAnchorLayoutRef = useCallback(
    (node) => {
      if (node != null) {
        node.measureInWindow(
          (x: number, y: number, width: number, height: number) => {
            setAnchorLayout({ x, y, width, height });
          },
        );
      }
    },
    [renderAnchor, visible],
  );

  let coordStyleTop = {
    left: anchorLayout.x,
    top: anchorLayout.y - 35,
  } as StyleProp<ViewStyle>;

  let coordStyleBottom = {
    left: anchorLayout.x,
    top: anchorLayout.y + 25,
  } as StyleProp<ViewStyle>;

  let coordStyleLeft = {
    left: anchorLayout.x - anchorLayout.width - 10,
    top: anchorLayout.y - 10,
  } as StyleProp<ViewStyle>;

  let coordStyleRight = {
    left: anchorLayout.x + anchorLayout.width + 10,
    top: anchorLayout.y - 10,
  } as StyleProp<ViewStyle>;

  let bubblePositionStyle =
    placement === 'top'
      ? coordStyleTop
      : placement === 'bottom'
      ? coordStyleBottom
      : placement === 'left'
      ? coordStyleLeft
      : coordStyleRight;

  return (
    <View style={[style]} {...otherProps}>
      {visible && (
        <Portal>
          <View style={[styles.bubble, bubblePositionStyle]}>
            <View style={styles.content}>
              <Text size="extraSmall" color={WHITE}>
                {content}
              </Text>
            </View>
          </View>
        </Portal>
      )}
      <View ref={measureAnchorLayoutRef}>{renderAnchor()}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  bubble: {
    position: 'absolute',
  },
  content: {
    backgroundColor: BLACK,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingHorizontal: 16,
    borderRadius: 5,
    paddingVertical: 8,
  },
  bubbleTop: { bottom: 43, left: -10 },
  bubbleBottom: { top: 43, left: -10 },
  bubbleLeft: { right: 43, top: -5 },
  bubbleRight: { left: 43, top: -5 },
});
