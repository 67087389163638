import React, { useState, useContext, Fragment } from 'react';
import { View, StyleSheet } from 'react-native';
import { useMutation } from 'react-fetching-library';
import { Card, Text, Button, TextInput, DataContext } from '../core-ui';
import { GROUP_TEXT } from '../constants/grouptextdropdown';
import { updateLinkActionCreator } from '../query/links';
import { RED, DARK_GREY } from '../constants/colors';
import {
  checkValidPathname,
  checkValidDestination,
  generateRandomPathname,
} from '../helpers';
import { ShortLinkData } from './Table/types';

type Props = {
  handleDelete: () => void;
  onCancelPress: () => void;
  optimisticDelete: (selectedData: ShortLinkData) => void;
  newUpdateFunc: (pathname: string, destination: string, id: string) => void;
};

export default function EditShortLinkModal(props: Props) {
  let { data: selectedData, set, prefix, setPrefix } = useContext(DataContext);
  let { onCancelPress, newUpdateFunc, handleDelete } = props;
  let originalPathname = selectedData.originalPathname as string;
  let destination = selectedData.destination as string;
  let lastEdited = selectedData.lastEditedBy as string;
  let [isShortLinkError, setIsShortLinkError] = useState(false);
  let [shortLinkError, setShortLinkError] = useState('');
  let [isDestinationError, setIsDestinationError] = useState(false);
  let [destinationError, setDestinationError] = useState('');

  const { loading: isUpdateLoading } = useMutation(updateLinkActionCreator);

  let handleInputError = () => {
    let trimmedPathname = originalPathname.trim();
    let trimmedDestination = destination.trim();

    if (!checkValidPathname(trimmedPathname)) {
      setIsShortLinkError(true);
      setShortLinkError(
        'Short link must not have symbol(/, ?, %, etc) Except dot(.), dash(-),and underscore(_).',
      );
    } else {
      setIsShortLinkError(false);
      setShortLinkError('');
    }
    if (trimmedDestination === '') {
      setIsDestinationError(true);
      setDestinationError('Destination must be filled.');
    } else if (!checkValidDestination(trimmedDestination)) {
      setIsDestinationError(true);
      setDestinationError('Destination should be a valid url.');
    } else {
      setIsDestinationError(false);
      setDestinationError('');
    }
  };

  let handleCancelBtn = () => {
    onCancelPress();
  };

  let handleUpdateLink = async () => {
    if (isUpdateLoading) {
      return;
    }
    handleInputError();
    let trimmedPathname = originalPathname.trim();
    let trimmedDestination = destination.trim();

    let isPathnameValid = checkValidPathname(trimmedPathname);
    let isDestinationValid =
      trimmedDestination !== '' && checkValidDestination(trimmedDestination);
    let isAllInputValid = isPathnameValid && isDestinationValid;
    if (isAllInputValid) {
      let pathname =
        originalPathname === '' ? generateRandomPathname() : originalPathname;
      newUpdateFunc(pathname, trimmedDestination, selectedData.id as string);
    }
  };

  return (
    <Card style={{ width: '50%' }}>
      <Card.Header style={{ justifyContent: 'space-between' }}>
        <Text weight="bold" size="medium">
          Edit Short Link
        </Text>
        <Text weight="bold" color={DARK_GREY}>
          Last Edited By {lastEdited}
        </Text>
      </Card.Header>
      <Card.Content style={styles.formContent}>
        <TextInput
          label="Short Link"
          value={originalPathname as string}
          groupText
          optionsValue={['kfox.io/']}
          textInputContainerStyle={{ borderWidth: 1 }}
          containerStyle={styles.shortLink}
          onChangeText={(text) => {
            if (originalPathname.length + text.length > 30) {
              let textLength = originalPathname.length - 30;
              let slicedText = text.slice(
                0,
                originalPathname.length - textLength,
              );
              set({ ...selectedData, originalPathname: slicedText });
            } else {
              set({ ...selectedData, originalPathname: text });
            }
          }}
          isError={isShortLinkError}
          errorMessage={shortLinkError}
          maxInputLimit={30}
        />
        <TextInput
          label="Destination"
          value={destination as string}
          optionsValue={GROUP_TEXT}
          textInputContainerStyle={{ borderWidth: 1 }}
          containerStyle={styles.destination}
          onChangeText={(text) => {
            let splittedText = text.split('://');
            let regEx = /https|http/g;
            if (splittedText.length <= 1) {
              set({
                ...selectedData,
                destination: text,
              });
            } else {
              if (splittedText[0] === 'https' || splittedText[0] === 'http') {
                setPrefix(`${splittedText[0]}://`);
                let securedDestination = splittedText
                  .join('')
                  .replace(regEx, '');
                set({
                  ...selectedData,
                  destination: securedDestination,
                });
              } else {
                let securedDestination = splittedText
                  .join('')
                  .replace(regEx, '');
                set({
                  ...selectedData,
                  destination: securedDestination,
                });
              }
            }
          }}
          isError={isDestinationError}
          errorMessage={destinationError}
          selectedValue={prefix}
          groupText
          onOptionPress={setPrefix}
          containerStyleDropdown={styles.prefix}
        />
      </Card.Content>
      <Card.Footer>
        <Fragment>
          <Button
            icon="delete-forever"
            onPress={handleDelete}
            mode="text"
            textColor={RED}
            style={styles.deleteButton}
          >
            Delete Short link
          </Button>
          <View style={styles.formFooter}>
            <Button mode="text" onPress={handleCancelBtn}>
              Cancel
            </Button>
            <Button
              onPress={handleUpdateLink}
              style={styles.button}
              isLoading={isUpdateLoading}
            >
              Update Link
            </Button>
          </View>
        </Fragment>
      </Card.Footer>
    </Card>
  );
}

const styles = StyleSheet.create({
  formContent: { flexDirection: 'row' },
  shortLink: { marginRight: 8, width: '50%' },
  destination: {
    width: '50%',
    marginLeft: 8,
    paddingRight: 16,
  },
  prefix: {
    width: '98%',
    marginLeft: 5,
    borderRightWidth: 1,
    borderLeftWidth: 1,
  },
  button: {
    width: 120,
  },
  formFooter: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  deleteButton: { marginLeft: -10 },
});
