import React, { Dispatch, SetStateAction, useContext } from 'react';
import { TouchableOpacity, View, StyleSheet, Text } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { GREY, LIGHT_ORANGE } from '../constants/colors';
import { Tag } from '../query/tags';
import { GlobalSnackbar } from '../core-ui';

type Props = {
  onManage: boolean;
  tags: Array<Tag>;
  onTagsDelete?: (index: number) => void;
  newTempTags?: Array<Tag>;
  setTempTags?: Dispatch<SetStateAction<Array<Tag>>>;
};

export default function Tags(props: Props) {
  let snackbarContext = useContext(GlobalSnackbar);
  let { onManage, tags, onTagsDelete, newTempTags, setTempTags } = props;

  let icon = [];
  let tagsSet = new Set(newTempTags && newTempTags.map((item) => item.id));
  for (let i = 0; i < tags.length; i++) {
    let isActive = tagsSet.has(tags[i].id);
    let tagsStyle = [styles.tagsContainer, isActive && styles.activeTags];
    {
      !onManage
        ? icon.push(
            <TouchableOpacity
              style={tagsStyle}
              key={i}
              onPress={() => {
                if (isActive && setTempTags) {
                  tagsSet.delete(tags[i].id);
                  let newTags =
                    newTempTags &&
                    newTempTags.filter((item) => tagsSet.has(item.id));
                  setTempTags(newTags ? newTags : []);
                } else {
                  if (setTempTags && newTempTags && newTempTags.length < 3) {
                    tagsSet.add(tags[i].id);
                    let newTags = tags.filter((tag) => {
                      if (tagsSet.has(tag.id)) {
                        return tag;
                      }
                    });

                    setTempTags(newTags ? newTags : []);
                  } else {
                    snackbarContext.show('Tags Limit Reached', 3000);
                  }
                }
              }}
            >
              <View
                style={[styles.tagsIcon, { backgroundColor: `${tags[i].hex}` }]}
              />
              <Text style={{ marginRight: 10 }}>{tags[i].name}</Text>
            </TouchableOpacity>,
          )
        : icon.push(
            <View style={styles.tagsContainer}>
              <View
                style={[styles.tagsIcon, { backgroundColor: `${tags[i].hex}` }]}
              />
              <Text style={styles.iconText}>{tags[i].name}</Text>
              <TouchableOpacity
                style={{ marginRight: 5 }}
                onPress={() => {
                  if (onTagsDelete) {
                    onTagsDelete(i);
                  }
                }}
              >
                <MaterialIcons name="close" size={22} />
              </TouchableOpacity>
            </View>,
          );
    }
  }
  return <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>{icon}</View>;
}

const styles = StyleSheet.create({
  tagsIcon: {
    width: 20,
    height: 20,
    borderRadius: 2,
    marginHorizontal: 12,
  },
  tagsContainer: {
    flexDirection: 'row',
    marginRight: 16,
    borderWidth: 1,
    height: 42,
    alignItems: 'center',
    borderColor: GREY,
    borderRadius: 4,
    marginVertical: 8,
  },
  activeTags: {
    borderColor: LIGHT_ORANGE,
  },
  iconText: { marginRight: 10 },
});
