import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { Card, Text, Button } from '../core-ui';
import { RED } from '../constants/colors';

type Props = {
  onDeletePress: () => void;
  onCancelPress: () => void;
};

export default function DeleteModal(props: Props) {
  let { onDeletePress, onCancelPress } = props;

  let [isLoading, setIsLoading] = useState(false);

  let handleDeleteBtn = () => {
    onDeletePress();
    setIsLoading(true);
  };

  let handleCancelBtn = () => {
    onCancelPress();
  };

  return (
    <Card style={styles.modalContainer}>
      <Card.Header>
        <Text weight="bold">Delete Short Link</Text>
      </Card.Header>
      <Card.Content>
        <Text>
          Delete short link will permanently remove this short link from our
          database.
        </Text>
      </Card.Content>
      <Card.Footer style={styles.cardFooter}>
        <Button mode="text" onPress={handleCancelBtn} disabled={isLoading}>
          No, Cancel
        </Button>
        <Button
          textColor={RED}
          mode="text"
          onPress={handleDeleteBtn}
          isLoading={isLoading}
        >
          Yes, Delete
        </Button>
      </Card.Footer>
    </Card>
  );
}

const styles = StyleSheet.create({
  modalContainer: { width: '40%' },
  cardFooter: { justifyContent: 'flex-end' },
});
