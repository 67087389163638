import React from 'react';
import {
  IconButton as IconButtonBase,
  IconButtonProps,
} from 'react-native-paper';

import { ICON_BUTTON_SIZE } from '../constants/iconButton';
import { ORANGE, BLACK } from '../constants/colors';

type Props = IconButtonProps & {
  icon:
    | 'more-vert'
    | 'help-outline'
    | 'navigate-next'
    | 'filter-none'
    | 'navigate-before'
    | 'keyboard-arrow-down'
    | 'keyboard-arrow-up'
    | 'content-copy';
};

function IconButton(props: Props) {
  let { icon, disabled, color, size, ...otherProps } = props;
  let iconColor = disabled ? BLACK : color ? color : ORANGE;
  return (
    <IconButtonBase
      icon={icon}
      color={iconColor}
      size={size ? size : ICON_BUTTON_SIZE}
      disabled={disabled}
      {...otherProps}
    />
  );
}

export default IconButton;
