import { createClient, RequestInterceptor } from 'react-fetching-library';
import { getToken } from '../helpers/getToken';

const requestHostInterceptor: RequestInterceptor = (
  _client,
) => async (action: {}) => {
  let token = await getToken();
  return {
    ...action,
    headers: {
      'X-Token': token,
    },
  };
};

const client = createClient({
  requestInterceptors: [requestHostInterceptor],
  responseInterceptors: [],
});

export default client;
