import { Action } from 'react-fetching-library';
import { API_URL } from '../constants/api';

export type Tag = {
  id: string;
  hex: string;
  name: string;
};

type AssignTag = {
  id: string;
  tags: Array<Tag>;
};

type ManageTag = {
  addedTags: Array<Tag>;
  deletedTags: Array<Tag>;
};

export function getTagsActionCreator(): Action {
  let endpoint = `${API_URL}tags`;
  let action = {
    method: 'GET',
    endpoint,
  };
  return action;
}

export function assignTagActionCreator({ tags, id }: AssignTag): Action {
  let action = {
    method: 'PUT',
    endpoint: `${API_URL}link/assign/${id}`,
    body: {
      tags,
    },
  };
  return action;
}

export function manageTagActionCreator({
  addedTags,
  deletedTags,
}: ManageTag): Action {
  let action = {
    method: 'POST',
    endpoint: `${API_URL}tag/manage`,
    body: {
      addedTags,
      deletedTags,
    },
  };
  return action;
}
