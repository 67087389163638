import { Action } from 'react-fetching-library';
import { API_URL } from '../constants/api';
import { Tag } from './tags';

export type Links = {
  id: string;
  createdAt: string;
  destination: string;
  originalPathname: string;
  lastEditedBy: string;
};

type UpdateParam = {
  id: string;
  originalPathname: string;
  destination: string;
};

type CreateParam = {
  originalPathname: string;
  destination: string;
};

type DeleteParam = {
  id: string;
};

type CheckShortLinkParam = {
  shortLink: string;
};

export function getLinksActionCreator(
  endCursor: string,
  rowLimit: string,
  searchString?: string,
  filtertags?: Array<Tag>,
): Action {
  let endpoint = `${API_URL}links?${rowLimit ? `rowLimit=${rowLimit}` : ''}${
    endCursor ? `&endCursor=${endCursor}` : ''
  }${searchString ? `&searchString=${searchString}` : ''}${
    filtertags ? filtertags.map((tag) => `&tags=${tag.name}`).join('') : ''
  }`;
  let action = {
    method: 'GET',
    endpoint,
  };
  return action;
}

export function createLinkActionCreator({
  originalPathname,
  destination,
}: CreateParam): Action {
  let action = {
    method: 'POST',
    endpoint: `${API_URL}link/create`,
    body: {
      originalPathname,
      destination,
    },
  };
  return action;
}

export function updateLinkActionCreator({
  originalPathname,
  destination,
  id,
}: UpdateParam): Action {
  let action = {
    method: 'PUT',
    endpoint: `${API_URL}link/update/${id}`,
    body: {
      originalPathname,
      destination,
    },
  };
  return action;
}

export function deleteLinkActionCreator({ id }: DeleteParam): Action {
  let action = {
    method: 'DELETE',
    endpoint: `${API_URL}link/delete/${id}`,
  };
  return action;
}

export function checkShortLinkActionCreator({
  shortLink,
}: CheckShortLinkParam): Action {
  let action = {
    method: 'GET',
    endpoint: `${API_URL}link/checklink/${shortLink}`,
  };
  return action;
}
