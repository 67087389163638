import React, { Fragment, useContext, useState } from 'react';
import { Clipboard, View } from 'react-native';
import { useMutation } from 'react-fetching-library';

import {
  Popover,
  GlobalMContext,
  DeleteModal,
  EditShortLinkModal,
  TagsModal,
  Tooltip,
} from '../components';
import { IconButton, DataContext, GlobalSnackbar, Text } from '../core-ui';
import { TableStructure, ShortLinkData } from '../components/Table/types';
import { GREY, WHITE } from '../constants/colors';
import { deleteLinkActionCreator } from '../query/links';
import { Tag } from '../query/tags';

export function getTableStructure(
  //temporary handling. need some refactoring after this
  handleOnDeleteSuccess: () => void,
  optimisticDelete: (selectedData: ShortLinkData) => void,
  newUpdateFunc: (pathname: string, destination: string, id: string) => void,
  allTags: Array<Tag>,
  optimisicAssignTag?: (id: string, newTempTags: Array<Tag>) => void,
): TableStructure {
  let dataContext = useContext(DataContext);
  let modalContext = useContext(GlobalMContext);
  let snackbarContext = useContext(GlobalSnackbar);
  const { mutate: deleteLink } = useMutation(deleteLinkActionCreator);
  let [activeID, setActiveId] = useState();
  let [activeTag, setActiveTag] = useState();

  let tableStructure: TableStructure = {
    originalPathname: {
      alias: 'Short Link',
      grid: 10,
      style: {
        header: { contentStyle: { color: WHITE } },
        body: { contentStyle: { fontWeight: '900' } },
      },
      render: (data) => {
        let isTrimmed = `kfox.io/${data.originalPathname}`.length > 20;
        return (
          <Tooltip
            visible={data.id === activeID && isTrimmed && !activeTag}
            placement="top"
            content={`kfox.io/${data.originalPathname}`}
            renderAnchor={() => (
              <Text
                weight="bold"
                numberOfLines={1}
                onPress={() => {
                  let destination = data.destination as string;
                  let newWindow = window.open(destination);
                  newWindow && newWindow.focus();
                }}
                onMouseOver={() => {
                  setActiveId(data.id as string);
                }}
                onMouseLeave={() => {
                  setActiveId('');
                }}
                style={{ width: 150 }}
              >
                kfox.io/{data.originalPathname}
              </Text>
            )}
            onMouseLeave={() => {
              setActiveId('');
            }}
          />
        );
      },
    },
    copyLink: {
      alias: '',
      style: { header: {}, body: {} },
      render: (data) => {
        return (
          <IconButton
            icon="content-copy"
            color={GREY}
            size={22}
            onPress={() => {
              Clipboard.setString(`https://kfox.io/${data.originalPathname}`);
              snackbarContext.show('Link has been copied!', 3000);
            }}
          />
        );
      },
    },
    destination: {
      grid: 25,
      style: {
        header: { contentStyle: { marginLeft: 18, color: WHITE } },
        body: {},
      },
      render: (data) => {
        return (
          <Text
            numberOfLines={1}
            onPress={() => {
              let destination = data.destination as string;
              let newWindow = window.open(destination);
              newWindow && newWindow.focus();
            }}
          >
            {data.destination}
          </Text>
        );
      },
    },
    tags: {
      alias: '',
      grid: 8,
      style: {
        header: { contentStyle: { marginLeft: 5 } },
        body: { containerStyle: { left: 25, justifyContent: 'flex-end' } },
      },
      render: (data) => {
        let tags = data.tags as Array<Tag>;
        if (tags.length != 0) {
          let icon = [];
          for (let i = 0; i < tags.length; i++) {
            let tag = tags[i] as Tag;
            let hexColor = tag.hex;
            icon.push(
              <Tooltip
                visible={tag.id === activeTag && data.id === activeID}
                placement="top"
                content={tag.name}
                renderAnchor={() => (
                  <View
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 2,
                      backgroundColor: hexColor,
                      marginRight: 10,
                    }}
                    onMouseOver={() => {
                      setActiveTag(tag.id as string);
                      setActiveId(data.id as string);
                    }}
                    onMouseLeave={() => {
                      setActiveTag('');
                      setActiveId('');
                    }}
                  />
                )}
                onMouseLeave={() => {
                  setActiveTag('');
                  setActiveId('');
                }}
              />,
            );
          }
          return <View style={{ flexDirection: 'row' }}>{icon}</View>;
        } else {
          return (
            <View
              style={{
                width: 20,
                height: 20,
                borderRadius: 2,
                backgroundColor: `${tags}`,
                marginRight: 10,
              }}
            ></View>
          );
        }
      },
    },
    action: {
      alias: '',
      style: {
        header: {},
        body: { containerStyle: { padding: 0 } },
      },
      render: (data) => {
        let handleDelete = async () => {
          optimisticDelete(data);
          try {
            let response = await deleteLink({ id: data.id as string });
            if (response.payload.status === 'success') {
              modalContext.hideModal();
              snackbarContext.show('Delete link success.', 3000);
              dataContext.clear();
              handleOnDeleteSuccess();
            } else {
              modalContext.hideModal();
              snackbarContext.show(response.payload.message, 3000);
            }
          } catch (error) {
            modalContext.hideModal();
            snackbarContext.show(error && error.message, 3000);
          }
        };
        return (
          <View>
            <Fragment>
              <Popover
                structures={[
                  {
                    title: 'Add/Remove Tags',
                    onItemPress: () => {
                      modalContext.showModal(
                        <TagsModal
                          hideModal={modalContext.hideModal}
                          onManage={false}
                          allTags={allTags}
                          selectedId={data.id}
                          currentTags={data.tags as Array<Tag>}
                          optimisticAssign={optimisicAssignTag}
                        />,
                      );
                    },
                  },
                  {
                    title: 'Edit Link',
                    onItemPress: () => {
                      if (data.destination) {
                        let destination = data.destination
                          .toString()
                          .split('://');

                        let prefix = `${destination[0]}://`;
                        let newDestination = destination[1];
                        let newData = { ...data, destination: newDestination };
                        dataContext.set(newData);
                        dataContext.setPrefix(prefix);
                      }
                      modalContext.showModal(
                        <EditShortLinkModal
                          onCancelPress={modalContext.hideModal}
                          optimisticDelete={optimisticDelete}
                          newUpdateFunc={newUpdateFunc}
                          handleDelete={handleDelete}
                        />,
                      );
                    },
                  },
                  {
                    title: 'Delete Link',
                    style: { color: 'red' },
                    onItemPress: () => {
                      modalContext.showModal(
                        <DeleteModal
                          onDeletePress={handleDelete}
                          onCancelPress={modalContext.hideModal}
                        />,
                        true,
                      );
                    },
                  },
                ]}
              />
            </Fragment>
          </View>
        );
      },
    },
  };

  return tableStructure;
}
