import { API_URL } from './../constants/api';
import { Action } from 'react-fetching-library';

export function authActionCreator(token: string): Action {
  let action = {
    method: 'POST',
    endpoint: `${API_URL}login`,
    body: {
      token,
    },
  };
  return action;
}
