import React from 'react';
import {
  Provider as PaperProvider,
  Theme,
  DefaultTheme,
} from 'react-native-paper';
import { ClientContextProvider } from 'react-fetching-library';
import firebase from 'firebase';

import { GlobalModal } from './components';
import { SelectedDataProvider, GlobalSnackbarProvider } from './core-ui';

import { ORANGE, BLACK, WHITE } from './constants/colors';
import { firebaseConfig } from './constants/firebaseConfig';
import client from './query/client';
import MainRoute from './route/MainRoute';
import './App.css';

const theme: Theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: ORANGE,
    text: BLACK,
    accent: WHITE,
  },
};

firebase.initializeApp(firebaseConfig);

export default function App() {
  return (
    <ClientContextProvider client={client}>
      <SelectedDataProvider>
        <PaperProvider theme={theme}>
          <GlobalSnackbarProvider>
            <GlobalModal>
              <MainRoute />
            </GlobalModal>
          </GlobalSnackbarProvider>
        </PaperProvider>
      </SelectedDataProvider>
    </ClientContextProvider>
  );
}
