import React, { useContext, useState, useEffect } from 'react';
import { StyleSheet, View, Image, AsyncStorage } from 'react-native';
import firebase from 'firebase';
import { useQuery, useMutation } from 'react-fetching-library';
import { LinearGradient } from 'expo-linear-gradient';

import {
  Table,
  GlobalMContext,
  Popover,
  SearchBar,
  CreateLinkModal,
  TagsModal,
} from '../components';
import { DataContext, GlobalSnackbar } from '../core-ui/';
import { WHITE } from '../constants/colors';
import { getTableStructure } from '../fixtures/structure';

import { kfoxLogo } from '../../assets/images';

import {
  getLinksActionCreator,
  updateLinkActionCreator,
  createLinkActionCreator,
} from '../query/links';

import { ShortLinkData } from '../components/Table/types';
import { getTagsActionCreator, Tag } from '../query/tags';

type Props = {
  setIsLogin: (state: boolean) => void;
};

export default function HomeScene(props: Props) {
  let { data: selectedData, clear, set, prefix } = useContext(DataContext);
  let snackbarContext = useContext(GlobalSnackbar);
  let modalContext = useContext(GlobalMContext);
  let [endCursor, setEndCursor] = useState('');
  let [lastIndex, setLastIndex] = useState(10);
  let [searchText, setSearchText] = useState('');
  let [tempData, setTempData] = useState([
    {
      id: '',
      destination: '',
      originalPathname: '',
      lastEditedBy: '',
      createdAt: '',
      tags: [],
      normalizedPathname: '',
    },
  ]);
  let [displayEmail, setDisplayEmail] = useState('');
  let [data, setData] = useState<Array<ShortLinkData>>([]);
  let [filterTags, setFilterTags] = useState<Array<Tag>>([]);
  let [isSearching, setIsSearching] = useState(false);

  let [tagData, setTagData] = useState<Array<Tag>>([]);
  let { setIsLogin } = props;

  let GET_LINKS = getLinksActionCreator(
    endCursor,
    '10',
    searchText,
    filterTags,
  );

  let GET_TAGS = getTagsActionCreator();

  const {
    loading: isGetLinkLoading,
    payload: getLinkPayload,
    query: getLink,
  } = useQuery(GET_LINKS, false);

  const { payload: getTagsPayload, query: getTags } = useQuery(GET_TAGS);

  let allData = getLinkPayload && getLinkPayload.data;
  let allTags = getTagsPayload && getTagsPayload.data;
  let [totalCount, setTotalCount] = useState();

  useEffect(() => {
    async function initialFetch() {
      let response = await getLink();
      if (response.payload.status === 'success') {
        setLastIndex(lastIndex + response.payload.data.length);
        setData([...data, ...response.payload.data]);
        setTempData([...data, ...response.payload.data]);
        setTotalCount(response.payload.totalCount);
      }
    }
    initialFetch();
  }, []);

  useEffect(() => {
    async function fetchMore() {
      try {
        let response = await getLink();
        if (
          response.payload.status === 'success' &&
          response.payload.data.length
        ) {
          setLastIndex(lastIndex + response.payload.data.length);
          setData([...data, ...response.payload.data]);
          setTempData([...data, ...response.payload.data]);
          setTotalCount(response.payload.totalCount);
        }
      } catch (error) {
        snackbarContext.show(error && error.message, 3000);
      }
    }

    if (endCursor) {
      fetchMore();
    }
  }, [endCursor]);

  useEffect(() => {
    async function searchLinks() {
      try {
        let response = await getLink();
        if (response.payload.status === 'success') {
          if (response.payload.data.length) {
            setData(response.payload.data);
            setTempData(response.payload.data);
            setEndCursor(
              response.payload.data[response.payload.data.length - 1].createdAt,
            );
            setLastIndex(response.payload.data.length);
            setTotalCount(response.payload.totalCount);
            setIsSearching(false);
          } else {
            let message =
              filterTags.length && searchText.length
                ? `Your Search ${searchText} with filter tag(s) : ${filterTags
                    .map((tag) => tag.name)
                    .join(',')} did not match any links.  🦊`
                : !searchText.length
                ? `The filter tag(s) : ${filterTags
                    .map((tag) => tag.name)
                    .join(',')} did not match any links  🦊`
                : `Your Search ${searchText} did not match any links.  🦊`;

            snackbarContext.show(message, 4000);
            setIsSearching(false);
          }
        }
      } catch (error) {
        snackbarContext.show(error && error.message, 3000);
        setIsSearching(false);
        setEndCursor(data[data.length - 1].createdAt as string);
      }
    }

    if (isSearching) {
      searchLinks();
    }
  }, [isSearching]);

  useEffect(() => {
    setDisplayEmail(getLinkPayload && getLinkPayload.user);
  }, [allData]);

  useEffect(() => {
    setTagData(allTags);
  }, [allTags]);

  const { mutate: updateLink } = useMutation(updateLinkActionCreator);

  const { mutate: createLink } = useMutation(createLinkActionCreator);

  let handleFetchMore = () => {
    if (lastIndex !== totalCount) {
      let lastData = data[data.length - 1];
      setEndCursor(lastData.createdAt as string);
    }
  };

  let refreshTags = async () => {
    let response = await getTags();
    if (response.payload.status === 'success' && response.payload.data.length) {
      setTagData([...tagData, ...response.payload.data]);
      let getLinkResponse = await getLink();
      if (getLinkResponse.payload.status === 'success') {
        setLastIndex(lastIndex + getLinkResponse.payload.data.length);
        setData(getLinkResponse.payload.data);
        setTempData(getLinkResponse.payload.data);
      }
      modalContext.hideModal();
      snackbarContext.show('Successfully Updated', 3000);
    }
  };

  let handleLogoutBtn = async () => {
    try {
      await firebase.auth().signOut();
      await AsyncStorage.removeItem('credential');
      setIsLogin(false);
    } catch (error) {
      snackbarContext.show(error && error.message, 3000);
    }
  };

  let newCreateFunc = async (
    id: string,
    pathname: string,
    destination: string,
  ) => {
    let newTempData = [
      {
        id,
        destination: `${prefix}${destination}`,
        originalPathname: pathname,
        lastEditedBy: displayEmail,
        normalizedPathname: pathname
          .toLowerCase()
          .replace(/[_]|\-|\.|\s|[?]|\%|\//g, ''),
        tags: [],
        createdAt: new Date().toISOString(),
      },
      ...tempData,
    ];
    setData(newTempData);
    setTotalCount(totalCount + 1);
    try {
      let response = await createLink({
        destination: `${prefix}${destination}`,
        originalPathname: pathname,
      });
      if (response.payload.status === 'success') {
        let newData = response.payload.data;
        setData(newData);
        setTempData(newData);
        snackbarContext.show('Create link success. 🦊', 3000);
      } else if (response.payload.status === 'fail') {
        setData(tempData);
        setTotalCount(totalCount - 1);
        snackbarContext.show(`${response.payload.message} 🦊`, 3000);
      }
    } catch (error) {
      setData(tempData);
      setTotalCount(totalCount - 1);
      snackbarContext.show(error && error.message, 3000);
    }
  };

  let newUpdateFunc = async (
    pathname: string,
    destination: string,
    id: string,
  ) => {
    modalContext.hideModal();
    let newTempData = [];
    newTempData = tempData.map((a) =>
      a.id === id
        ? Object.assign({}, a, {
            originalPathname: pathname,
            normalizedPathname: pathname
              .toLowerCase()
              .replace(/[_]|\-|\.|\s|[?]|\%|\//g, ''),
            destination: `${prefix}${destination}`,
            lastEditedBy: displayEmail,
          })
        : a,
    );
    setData(newTempData);
    try {
      let response = await updateLink({
        originalPathname: pathname,
        destination: `${prefix}${destination}`,
        id,
      });
      if (response.payload.status === 'success') {
        setTempData(newTempData);
        snackbarContext.show('Update link success. 🦊', 3000);
      } else if (response.payload.status === 'fail') {
        setData(tempData);
        snackbarContext.show(`${response.payload.message} 🦊`, 3000);
      }
    } catch (error) {
      setData(tempData);
      snackbarContext.show(error && error.message, 3000);
    }
  };

  let optimisticDelete = (data: ShortLinkData) => {
    let newTemp = tempData.filter((link) => link.id !== data.id);
    setData(newTemp);
    setTempData(newTemp);
    modalContext.hideModal();
    clear();
  };

  let optimisticAssignTag = (id: string, newTempTags: Array<Tag>) => {
    let newTempData = [];
    newTempData = tempData.map((a) =>
      a.id === id
        ? Object.assign({}, a, {
            tags: newTempTags,
          })
        : a,
    );
    setData(newTempData);
    setTempData(newTempData);
  };

  let onSearchPress = async () => {
    setEndCursor('');
    setIsSearching(true);
  };

  let handleFilterTags = async (tags: Array<Tag>) => {
    setEndCursor('');
    setFilterTags(tags);
    setIsSearching(true);
  };

  let onFilterPress = () => {
    modalContext.showModal(
      <TagsModal
        hideModal={modalContext.hideModal}
        onManage={false}
        onFilterTags={handleFilterTags}
        filterTags={filterTags}
        allTags={tagData}
        refreshTags={refreshTags}
      />,
    );
  };

  let onCreateLinkButtonPress = () => {
    set({ ...selectedData, originalPathname: '', destination: '' });
    modalContext.showModal(
      <CreateLinkModal
        hideModal={modalContext.hideModal}
        displayEmail={displayEmail}
        newCreateFunc={newCreateFunc}
      />,
    );
  };

  let handleOnDeleteSuccess = () => {
    setTotalCount(totalCount - 1);
  };

  let tableStructure = getTableStructure(
    handleOnDeleteSuccess,
    optimisticDelete,
    newUpdateFunc,
    tagData,
    optimisticAssignTag,
  );

  return (
    <LinearGradient
      colors={['#ff9f3c', '#ff8200']}
      style={styles.backgroundImage}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 0 }}
    >
      <View style={styles.container}>
        <View style={styles.header}>
          <Image source={kfoxLogo} style={styles.logo} />
          <Popover
            anchorStyle={styles.anchorStyle}
            structures={[
              {
                title: 'Manage Tags',
                onItemPress: () => {
                  modalContext.showModal(
                    <TagsModal
                      hideModal={modalContext.hideModal}
                      onManage={true}
                      allTags={tagData}
                      refreshTags={refreshTags}
                    />,
                  );
                },
              },
              {
                title: 'Log Out',
                onItemPress: handleLogoutBtn,
                style: { color: 'red' },
              },
            ]}
          />
        </View>
        <SearchBar
          searchText={searchText}
          placeholder={'Search Links'}
          onCreateLinkButtonPress={onCreateLinkButtonPress}
          onFilterPress={onFilterPress}
          onSearchPress={onSearchPress}
          setSearchText={setSearchText}
        />
        {data ? (
          <Table
            structure={tableStructure}
            data={data}
            rowHeight={51}
            dataCount={totalCount}
            isLoading={isGetLinkLoading}
            onFetchMore={handleFetchMore}
          />
        ) : null}
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  header: {
    marginVertical: 48,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '60%',
    alignSelf: 'center',
  },
  logo: {
    width: 55,
    height: 40,
  },
  formContent: { flexDirection: 'row', marginRight: 16 },
  formFooter: { flex: 1, flexDirection: 'row', justifyContent: 'flex-end' },
  rowSection: { flexDirection: 'row' },
  shortLink: { marginRight: 8, width: '50%' },
  destination: {
    width: '50%',
    marginLeft: 8,
  },
  prefix: {
    width: '98%',
    marginLeft: 5,
    borderRightWidth: 1,
    borderLeftWidth: 1,
  },
  button: {
    width: 120,
  },
  deleteButton: { marginLeft: -10 },
  backgroundImage: { height: 260 },
  logoutButton: {
    borderWidth: 1,
    borderColor: WHITE,
  },
  googleSignInText: { bottom: 4 },
  googleLogo: { width: 20, height: 20, marginRight: 12 },
  signInWrapper: { flexDirection: 'row', alignItems: 'center' },
  tooltipAnchor: { margin: 0 },
  tooltip: { marginRight: 10 },
  anchorStyle: { marginRight: 15 },
});
