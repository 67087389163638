import React, { Component } from 'react';

import { StyleSheet, View, Image } from 'react-native';
import { Text } from './../core-ui/';
import { VERY_LIGHT_GREY, DARK_GREY } from './../constants/colors';
import NotFound from '../../assets/images/not-found.png';

class NotFoundScene extends Component {
  render() {
    return (
      <View style={styles.container}>
        <Image
          source={NotFound}
          style={[styles.imageContainer, styles.marginItem]}
        />
        <Text weight="bold" size="extraLarge" style={styles.marginItem}>
          Ooops.. the page is gone!
        </Text>
        <Text color={DARK_GREY} size="extraLarge" style={styles.marginItem}>
          Sorry, the page that you looking for may be broken, changed, or even
          removed from this site.
        </Text>
      </View>
    );
  }
}
export default NotFoundScene;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: VERY_LIGHT_GREY,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: { width: 600, height: 300 },
  marginItem: {
    marginVertical: 12,
  },
});
