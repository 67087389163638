import React, { useCallback, useContext } from 'react';
import {
  FlatList,
  StyleSheet,
  View,
  ActivityIndicator,
  Text,
} from 'react-native';

import Cell from './Cell';
import {
  ShortLinkData,
  CellStyle,
  StructureElement,
  TableStructure,
} from './types';
import {
  WHITE,
  LIGHT_GREY,
  BLACK,
  VERY_LIGHT_ORANGE,
  ORANGE,
} from '../../constants/colors';
import { ROW_HEIGHT, NUMBER_OF_ROWS } from '../../constants/table';
import { DataContext } from '../../core-ui';

type TableBodyProps = {
  data: Array<ShortLinkData>;
  structure: TableStructure;
  isLoading: boolean;
  fetchMore: () => void;
  autoNumber?: boolean;
  style?: Array<CellStyle>;
  rowHeight?: number;
};

export default function TableBody(props: TableBodyProps) {
  let {
    data,
    autoNumber,
    structure,
    style,
    rowHeight,
    isLoading,
    fetchMore,
  } = props;
  let height = rowHeight || ROW_HEIGHT;
  let { isEdit, data: selectedData } = useContext(DataContext);

  let renderItem = useCallback(
    ({ item, index }) => {
      let rowData: ShortLinkData = autoNumber
        ? { no: index + 1, ...item }
        : item;

      let rowStructure = autoNumber
        ? { no: { style: { body: {}, header: {} } }, ...structure }
        : structure;

      let rowStyle = style && style[index % style.length];

      return (
        <TableRow
          isActive={isEdit && selectedData.id === rowData.id}
          structure={rowStructure}
          rowData={rowData}
          rowStyle={rowStyle}
          rowHeight={height}
        />
      );
    },
    [autoNumber, structure, style, height],
  );

  let emptyComponent = (
    <View
      style={[
        styles.rowsContainer,
        styles.noDataContainer,
        {
          height:
            Array.isArray(data) && data.length !== 0
              ? height * data.length
              : height * NUMBER_OF_ROWS[0],
        },
      ]}
    >
      <Text style={styles.noDataText}>No Data</Text>
    </View>
  );

  return isLoading && !data.length ? (
    <View
      style={[
        styles.rowsContainer,
        styles.loadingContainer,
        styles.shadowBackdrop,
        {
          height: '50vh',
        },
      ]}
    >
      <ActivityIndicator color={ORANGE} size={32} />
    </View>
  ) : (
    <View style={[styles.fixHeight, styles.shadowBackdrop]}>
      <FlatList
        data={data}
        contentContainerStyle={[styles.rowsContainer, styles.shadowBackdrop]}
        renderItem={renderItem}
        keyExtractor={(_, index) => index.toString()}
        ListEmptyComponent={emptyComponent}
        onEndReachedThreshold={0.1}
        onEndReached={fetchMore}
        extraData={data}
      />
    </View>
  );
}

type TableRowProps = {
  isActive: boolean;
  rowData: ShortLinkData;
  rowStyle?: CellStyle;
  rowHeight?: number;
  structure: { [key: string]: StructureElement };
};

function TableRow(props: TableRowProps) {
  let { rowData, rowStyle, structure, rowHeight, isActive } = props;

  let containerStyle = rowStyle ? rowStyle.containerStyle : {};
  let contentStyle = rowStyle ? rowStyle.contentStyle : {};

  let columns = Object.keys(structure);

  let rowContainerStyle = [styles.rowContainer, isActive && styles.activeRow];

  return (
    <View style={rowContainerStyle}>
      {columns &&
        columns.map((column) => {
          let {
            render,
            grid,
            style: {
              body: {
                containerStyle: columnContainerStyle,
                contentStyle: columnContentStyle,
              },
            },
          } = structure[column];
          let cellData = rowData[column] ? rowData[column] : '-';

          return (
            <Cell
              key={column}
              containerStyle={[columnContainerStyle, containerStyle]}
              contentStyle={[columnContentStyle, contentStyle]}
              height={rowHeight}
              grid={grid}
            >
              {render ? render(rowData) : cellData}
            </Cell>
          );
        })}
    </View>
  );
}

const styles = StyleSheet.create({
  rowsContainer: {
    backgroundColor: WHITE,
    borderRadius: 4,
    borderColor: LIGHT_GREY,
    borderWidth: StyleSheet.hairlineWidth,
  },
  rowContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  activeRow: {
    backgroundColor: VERY_LIGHT_ORANGE,
  },
  indicatorContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: 'red ',
  },
  noDataText: {
    fontSize: 32,
  },
  noDataContainer: { justifyContent: 'center', alignItems: 'center' },
  loadingContainer: { justifyContent: 'center' },
  fixHeight: { height: '50vh' },
  shadowBackdrop: {
    shadowColor: BLACK,
    shadowOpacity: 0.08,
    shadowRadius: 12,
    shadowOffset: {
      height: 4,
      width: 0,
    },
  },
});
