import React, { ReactNode } from 'react';
import { Text as BaseText } from 'react-native-paper';
import { StyleSheet, StyleProp, TextStyle, TextProps } from 'react-native';
import {
  FONT_SIZE,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_NORMAL,
} from '../constants/text';
import '../../assets/css/index.css';
import { BLACK } from '../constants/colors';

type Props = TextProps & {
  size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge';
  weight?: 'normal' | 'bold';
  color?: string;
  children?: ReactNode;
};

function Text(props: Props) {
  let {
    weight = 'normal',
    style,
    size,
    color = BLACK,
    children,
    ...otherProps
  } = props;
  let fontSize = FONT_SIZE[size || 'small'];
  let fontWeight = weight === 'normal' ? styles.default : styles.bold;
  let textStyle: StyleProp<TextStyle> = [
    styles.default,
    { fontSize },
    fontWeight,
    { color },
    style,
  ];
  return (
    <BaseText style={textStyle} {...otherProps}>
      {children}
    </BaseText>
  );
}

export default Text;

const styles = StyleSheet.create({
  default: {
    fontWeight: FONT_WEIGHT_NORMAL,
    fontFamily: 'Futura-Book',
  },
  bold: {
    fontWeight: FONT_WEIGHT_BOLD,
    fontFamily: 'Futura-Book',
  },
});
