export default function generateRandomPathname() {
  let date = new Date();
  let yearStr = date.getFullYear().toString(36);
  let dateStr = date.getDate().toString(36);
  let timeStr = date
    .getTime()
    .toString(36)
    .substr(2, 3);
  return (
    Math.random()
      .toString(36)
      .substr(2, 3) +
    dateStr +
    timeStr +
    yearStr
  );
}
