import React, { ReactNode, Children } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '../../core-ui';
import { CellStyle } from './types';

type Props = CellStyle & {
  children: ReactNode;
  height?: number;
  grid?: number;
};

export default function Cell(props: Props) {
  let { children, contentStyle, containerStyle, height, grid } = props;

  let cellStyle = [
    styles.container,
    containerStyle,
    height ? { height } : null,
    grid ? { flex: grid } : null,
  ];

  return (
    <View style={cellStyle}>
      {typeof children === 'string' || typeof children === 'number' ? (
        <Text numberOfLines={1} style={[styles.text, contentStyle]}>
          {children.toString()}
        </Text>
      ) : (
        Children.only(children)
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    minWidth: 50,
  },
  text: {
    textAlign: 'left',
  },
});
