import React from 'react';
import { View, StyleSheet } from 'react-native';
import Text from '../../core-ui/Text';

type TablePaginationProps = {
  dataCount: number;
};

export default function TablePagination(props: TablePaginationProps) {
  let { dataCount = 0 } = props;

  return (
    <View style={styles.paginationContainer}>
      <View style={styles.paginationLeftSection}>
        <Text>Total Rows: {dataCount}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  paginationContainer: {
    marginTop: 25,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  paginationRightSection: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginVertical: 16,
  },
  paginationLeftSection: {
    justifyContent: 'center',
  },
  dropdown: {
    marginHorizontal: 16,
  },
  shownDataDetail: {
    marginHorizontal: 36,
  },
});
