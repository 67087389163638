import React, {
  useState,
  Fragment,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import { useMutation } from 'react-fetching-library';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Card, Text, Button, TextInput, GlobalSnackbar } from '../core-ui';
import { GREY, ORANGE, BLACK, WHITE } from '../constants/colors';
import { SearchBar, Tags } from '.';
import {
  Tag,
  assignTagActionCreator,
  manageTagActionCreator,
} from '../query/tags';

type Props = {
  hideModal: () => void;
  onManage: boolean;
  allTags: Array<Tag>;
  onFilterTags?: (tags: Array<Tag>) => void;
  filterTags?: Array<Tag>;
  selectedId?: ReactNode;
  currentTags?: Array<Tag>;
  refreshTags?: () => void;
  optimisticAssign?: (id: string, newTempTags: Array<Tag>) => void;
};

const tempColor = [
  ['#fb7e7d', '#ffb1f6', '#c6a2ff', '#77d3fd', '#b5ebe9'],
  ['#8ce47a', '#d7f586', '#f9ec77', '#ffb75a', '#dedede'],
];

export default function TagsModal(props: Props) {
  let {
    hideModal,
    onManage,
    onFilterTags,
    allTags,
    selectedId,
    currentTags,
    filterTags,
    refreshTags,
    optimisticAssign,
  } = props;
  let snackbarContext = useContext(GlobalSnackbar);
  let [newTempTags, setTempTags] = useState<Array<Tag>>([]);
  let [tagsName, setTagsName] = useState('');
  let [addedTags, setAddedTags] = useState<Array<Tag>>([]);
  let [removedTags, setRemovedTags] = useState<Array<Tag>>([]);
  let [personalTags, setPersonalTags] = useState<Array<Tag>>(allTags);
  let [hex, setHex] = useState(tempColor[0][0]);
  let [searchTag, setSearchText] = useState('');
  let [isSearching, setIsSearching] = useState(false);
  let [errorTagsName, setErrorTagsName] = useState(false);
  let [errorMessage, setErrorTagsMessage] = useState('');
  let [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (currentTags && currentTags.length) {
      setTempTags(currentTags);
    } else if (filterTags && filterTags.length) {
      setTempTags(filterTags);
    }
  }, []);

  useEffect(() => {
    let newPersonalTags: Array<Tag> = [];
    for (let i = 0; i < allTags.length; i++) {
      if (
        allTags[i].name.toLowerCase().includes(searchTag.toLowerCase()) === true
      ) {
        newPersonalTags.push(allTags[i]);
      }
    }
    for (let i = 0; i < addedTags.length; i++) {
      if (
        addedTags[i].name.toLowerCase().includes(searchTag.toLowerCase()) ===
        true
      ) {
        newPersonalTags.push(addedTags[i]);
      }
    }
    if (isSearching) {
      setPersonalTags(newPersonalTags);
      setIsSearching(false);
    }
  }, [isSearching]);

  const { mutate: assignTag, loading: isAssignLoading } = useMutation(
    assignTagActionCreator,
  );

  const { mutate: manageTag, loading: isManageTagLoading } = useMutation(
    manageTagActionCreator,
  );

  let handleCancelBtn = () => {
    personalTags.push(...removedTags);
    hideModal();
  };

  let handleSaveTags = async () => {
    if (removedTags.length === 0 && addedTags.length === 0) {
      hideModal();
    } else {
      try {
        setIsVisible(false);
        let response = await manageTag({ addedTags, deletedTags: removedTags });
        if (response.payload.status === 'success') {
          setPersonalTags(response.payload.data);
          refreshTags && refreshTags();
        } else {
          snackbarContext.show('Fail to Update Tags', 3000);
        }
        hideModal();
      } catch (error) {
        snackbarContext.show('Fail to Update Tags', 3000);
      }
    }
  };

  let onTagsDelete = (index: number) => {
    let tempData = personalTags;
    let removed = tempData.splice(index, 1);
    setPersonalTags(tempData);
    let undo: Array<Tag> = [];
    for (let i = 0; i < addedTags.length; i++) {
      if (removed[0].id === addedTags[i].id) {
        undo = addedTags.splice(i, 1);
      }
    }
    if (undo.length) {
      setRemovedTags([...removedTags]);
    } else {
      setRemovedTags([...removedTags, ...removed]);
    }
  };

  let addTags = async () => {
    let trimmedTagsName = tagsName.trim();
    if (trimmedTagsName.length === 0) {
      setErrorTagsName(true);
      setErrorTagsMessage('Tag name cannot be empty');
      return;
    }
    let checkTags = personalTags.filter(
      (item) =>
        tagsName.trim().toLowerCase() === item.name.trim().toLowerCase(),
    );
    if (checkTags.length) {
      setErrorTagsName(true);
      setErrorTagsMessage('Tag name has been taken');
    } else {
      if (
        addedTags.find(
          (tags) =>
            tags.name.trim().toLowerCase() === tagsName.trim().toLowerCase(),
        )
      ) {
        setErrorTagsName(true);
        setErrorTagsMessage('Tag name has been added');
      } else {
        setErrorTagsName(false);
        setErrorTagsMessage('');
        let tempForAdd = {
          id: Math.random().toString(),
          name: trimmedTagsName,
          hex,
        };
        let newData = [...personalTags, tempForAdd];
        setPersonalTags(newData);
        let b = [];
        b.push(tempForAdd);
        setAddedTags([...addedTags, ...b]);
      }
    }
  };

  let assignTags = async (id: string) => {
    try {
      let response = await assignTag({
        tags: newTempTags,
        id,
      });
      if (response.payload.status === 'success' && optimisticAssign) {
        snackbarContext.show('Succesfuly Updated', 3000);
        optimisticAssign(id, newTempTags);
      } else if (response.payload.status === 'fail') {
        snackbarContext.show('Fail to Assign', 3000);
      }
    } catch (error) {
      snackbarContext.show(error && error.message, 3000);
    }

    hideModal();
  };

  let onChangeTagsText = (text: string) => {
    if (tagsName.length + text.length > 12) {
      let textLength = tagsName.length - 12;
      let slicedText = text.slice(0, tagsName.length - textLength);
      setTagsName(slicedText);
    } else {
      setTagsName(text);
    }
  };

  let onCancelPress = () => {
    setIsVisible(false);
  };

  let onSearchTag = async () => {
    setIsSearching(true);
  };

  let onPressSaveSearch = (newTempTags: Array<Tag>) => {
    onFilterTags && onFilterTags(newTempTags);
    hideModal();
  };

  return onManage ? (
    <Card style={styles.card}>
      <Card.Header style={styles.manageHeader}>
        <Text weight="bold" size="medium">
          Manage Tags
        </Text>
      </Card.Header>
      <Card.Content style={styles.formContent} dividerContent={true}>
        <View style={styles.headerContainer}>
          <TextInput
            value={tagsName}
            placeholder={'Tags Name'}
            textInputContainerStyle={{ borderWidth: 1 }}
            containerStyle={styles.tagsName}
            onChangeText={onChangeTagsText}
            maxInputLimit={12}
            isError={errorTagsName}
            errorMessage={errorMessage}
          />
          <TextInput
            value={hex}
            groupText
            tagsValue={tempColor}
            selectedValue={hex}
            onChangeText={(text) => {
              setHex(text);
            }}
            textInputContainerStyle={{ borderWidth: 1 }}
            containerStyle={styles.destination}
            containerStyleDropdown={styles.prefix}
            onPressTags={setHex}
          />

          <Button style={styles.buttonAddTags} onPress={addTags}>
            Add Tags
          </Button>
        </View>
      </Card.Content>
      <Card.Footer>
        <View style={styles.manageFooter}>
          <SearchBar
            searchText={searchTag}
            onSearchPress={onSearchTag}
            setSearchText={setSearchText}
            placeholder={'Search tags'}
          />
          <ScrollView style={styles.tagsScrollView}>
            <Tags
              onManage={true}
              tags={personalTags}
              onTagsDelete={onTagsDelete}
            />
          </ScrollView>
          <View style={styles.formFooter2}>
            <Button
              mode="text"
              onPress={handleCancelBtn}
              isLoading={isManageTagLoading}
              style={{ right: 12 }}
            >
              Cancel
            </Button>
            <Button
              onPress={() => {
                if (removedTags.length != 0) {
                  setIsVisible(true);
                } else {
                  handleSaveTags();
                }
              }}
              isLoading={isManageTagLoading}
              style={styles.button}
            >
              Save Changes
            </Button>
            {isVisible ? (
              <ClickAwayListener
                onClickAway={() => {
                  setIsVisible(false);
                }}
              >
                <View style={styles.dialog}>
                  <View style={{ flex: 2 }}>
                    <Text size="small" style={styles.dialogHeader}>
                      There is no undo. This will remove all tags from all
                      links.
                    </Text>
                  </View>
                  <View style={styles.dialogFooter}>
                    <TouchableOpacity onPress={onCancelPress}>
                      <Text size="small" weight="bold">
                        Cancel
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={handleSaveTags}>
                      <Text color={ORANGE} size="small" weight="bold">
                        Delete
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </ClickAwayListener>
            ) : null}
          </View>
        </View>
      </Card.Footer>
    </Card>
  ) : (
    <Card style={styles.card}>
      <Card.Header style={{ justifyContent: 'space-between' }}>
        {onFilterTags ? (
          <Text weight="bold" size="medium">
            Filter By Tags
          </Text>
        ) : (
          <Text weight="bold" size="medium">
            Assign Tags
          </Text>
        )}
      </Card.Header>
      <Card.Content style={styles.formContent}>
        <View style={{ flex: 1 }}>
          <View>
            <SearchBar
              searchText={searchTag}
              onSearchPress={onSearchTag}
              setSearchText={setSearchText}
              placeholder={'Search tags'}
            />
          </View>
          <ScrollView style={styles.tagsScrollView}>
            <Tags
              onManage={false}
              tags={personalTags}
              newTempTags={newTempTags}
              setTempTags={setTempTags}
            />
          </ScrollView>
        </View>
      </Card.Content>
      <Card.Footer>
        <Fragment>
          <View style={styles.formFooter}>
            <TouchableOpacity
              style={styles.clearTags}
              onPress={() => {
                setTempTags([]);
              }}
            >
              <Text color={ORANGE} weight="bold" size="small">
                Clear Tags
              </Text>
            </TouchableOpacity>
            <View style={{ flexDirection: 'row' }}>
              <Button
                mode="text"
                onPress={handleCancelBtn}
                isLoading={isAssignLoading}
              >
                Cancel
              </Button>
              <Button
                onPress={() => {
                  if (selectedId) {
                    assignTags(selectedId as string);
                  } else if (onFilterTags) {
                    onPressSaveSearch(newTempTags);
                  }
                }}
                style={styles.button}
                isLoading={isAssignLoading}
              >
                Save Changes
              </Button>
            </View>
          </View>
        </Fragment>
      </Card.Footer>
    </Card>
  );
}

const styles = StyleSheet.create({
  formContent: {
    flexDirection: 'row',
    flex: 1,
  },
  tagsName: { marginRight: 8, width: '40%', marginTop: -9 },
  headerContainer: {
    width: '97%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  destination: {
    width: '40%',
    marginHorizontal: 8,
    marginTop: -9,
  },
  clearTags: { justifyContent: 'center', left: 16 },
  tagsScrollView: {
    maxWidth: '100%',
    height: 180,
  },
  buttonAddTags: {
    width: 100,
    marginLeft: 8,
    marginRight: 16,
    shadowColor: BLACK,
    shadowOpacity: 0.2,
    shadowRadius: 12,
    shadowOffset: {
      height: 4,
      width: 0,
    },
  },
  prefix: {
    width: '98%',
    marginLeft: 5,
    borderRightWidth: 1,
    borderLeftWidth: 1,
  },
  button: {
    width: 140,
    marginLeft: 8,
    shadowColor: BLACK,
    shadowOpacity: 0.2,
    shadowRadius: 12,
    shadowOffset: {
      height: 4,
      width: 0,
    },
  },
  formFooter: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 16,
  },
  formFooter2: {
    paddingTop: 16,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  deleteButton: { marginLeft: -10 },
  tagsContainer: {
    flexDirection: 'row',
    marginRight: 10,
    borderWidth: 1,
    height: 42,
    alignItems: 'center',
    borderColor: GREY,
  },
  iconTags: {
    width: 20,
    height: 20,
    borderRadius: 2,
    marginHorizontal: 10,
  },
  iconText: { marginRight: 10 },
  card: { maxWidth: '50%' },
  manageFooter: {
    width: '100%',
  },
  manageHeader: { justifyContent: 'space-between' },
  dialog: {
    position: 'absolute',
    backgroundColor: WHITE,
    zIndex: 1,
    right: '25%',
    width: 186,
    height: 150,
    borderRadius: 4,
    shadowColor: BLACK,
    flex: 1,
    shadowOpacity: 0.8,
    shadowRadius: 5.46,
  },
  dialogHeader: { paddingHorizontal: 16, paddingTop: 16 },
  dialogFooter: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 30,
    justifyContent: 'space-between',
    marginTop: 10,
  },
});
