export const FONT_SIZE = {
  extraSmall: 12,
  small: 14,
  medium: 16,
  large: 18,
  extraLarge: 36,
};

export const FONT_WEIGHT_NORMAL = '400';
export const FONT_WEIGHT_BOLD = '900';
