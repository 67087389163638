import React, { ReactNode, Fragment } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { Card as BaseCard } from 'react-native-paper';
import { LIGHT_GREY, BLACK, WHITE, GREY } from '../constants/colors';

type Props = {
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
  dividerContent?: boolean;
};

function Header({ children, style }: Props) {
  return (
    <Fragment>
      <View style={[styles.header, style]}>{children}</View>
      <View style={styles.divider} />
    </Fragment>
  );
}

function Footer({ children, style }: Props) {
  return <View style={[styles.footer, style]}>{children}</View>;
}

function Content({ children, style, dividerContent }: Props) {
  return dividerContent ? (
    <Fragment>
      <View style={[styles.content, style]}>{children}</View>
      <View style={styles.divider} />
    </Fragment>
  ) : (
    <View style={[styles.content, style]}>{children}</View>
  );
}
function Card({ children, style }: Props) {
  return <BaseCard style={[styles.root, style]}>{children}</BaseCard>;
}

Card.Header = Header;
Card.Content = Content;
Card.Footer = Footer;

export default Card;

const styles = StyleSheet.create({
  root: {
    width: '75%',
    backgroundColor: WHITE,
    borderRadius: 4,
    borderColor: LIGHT_GREY,
    borderWidth: StyleSheet.hairlineWidth,
    shadowColor: BLACK,
    shadowOpacity: 0.08,
    shadowRadius: 12,
    shadowOffset: {
      height: 4,
      width: 0,
    },
  },
  header: {
    flexDirection: 'row',
    padding: 16,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    padding: 16,
  },
  content: {
    padding: 16,
  },
  divider: {
    height: 1,
    backgroundColor: GREY,
    alignSelf: 'stretch',
  },
});
