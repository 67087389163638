import React from 'react';
import { StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { DataTable } from 'react-native-paper';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { StructureElement, ShortLinkData, CellStyle } from './types';
import TableFooter from './TableFooter';

type Props = {
  structure: { [key: string]: StructureElement };
  data: Array<ShortLinkData>;
  autoNumber?: boolean;
  headerStyle?: StyleProp<ViewStyle>;
  rowsStyle?: Array<CellStyle>;
  rowHeight?: number;
  dataCount: number;
  onFetchMore: () => void;
  isLoading: boolean;
};

export default function Table(props: Props) {
  let {
    structure,
    data,
    autoNumber = false,
    headerStyle,
    rowsStyle,
    rowHeight,
    dataCount,
    onFetchMore,
    isLoading,
  } = props;

  return (
    <DataTable style={styles.tableContainer}>
      <TableHeader
        style={headerStyle}
        structure={structure}
        autoNumber={autoNumber}
        rowHeight={rowHeight}
      />
      <TableBody
        style={rowsStyle}
        structure={structure}
        data={data}
        rowHeight={rowHeight}
        autoNumber={autoNumber}
        isLoading={isLoading}
        fetchMore={onFetchMore}
      />
      <TableFooter dataCount={dataCount} />
    </DataTable>
  );
}

const styles = StyleSheet.create({
  tableContainer: {
    width: '60%',
  },
});
