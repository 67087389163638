import React, { useContext, Fragment, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Card, Text, TextInput, DataContext, Button } from '../core-ui';
import {
  checkValidPathname,
  checkValidDestination,
  generateRandomPathname,
} from '../helpers';
import { GROUP_TEXT } from '../constants/grouptextdropdown';
import { useQuery } from 'react-fetching-library';
import { checkShortLinkActionCreator } from '../query/links';

type Props = {
  hideModal: () => void;
  displayEmail: string;
  newCreateFunc: (id: string, pathname: string, destination: string) => void;
};

export default function CreateLinkModal(props: Props) {
  let { data: selectedData, set, prefix, setPrefix } = useContext(DataContext);
  let { hideModal, newCreateFunc } = props;
  let originalPathname = selectedData.originalPathname as string;
  let destination = selectedData.destination as string;
  let [isShortLinkError, setIsShortLinkError] = useState(false);
  let [shortLinkError, setShortLinkError] = useState('');
  let [isDestinationError, setIsDestinationError] = useState(false);
  let [destinationError, setDestinationError] = useState('');

  let CHECK_SHORT_LINK = checkShortLinkActionCreator({
    shortLink: originalPathname.trim(),
  });

  let { query: checkShortLink } = useQuery(CHECK_SHORT_LINK);

  let handleCancelBtn = () => {
    hideModal();
  };

  let handleCreateLink = async () => {
    let trimmedPathname = originalPathname.trim();
    let trimmedDestination = destination.trim();
    let response = await checkShortLink();
    let isAvailable =
      response.payload.status === 'success' &&
      response.payload.message === 'available';

    if (!checkValidPathname(trimmedPathname)) {
      setIsShortLinkError(true);
      setShortLinkError(
        'Short link must not have symbol(/, ?, %, etc) Except dot(.), dash(-),and underscore(_).',
      );
    } else {
      if (!isAvailable) {
        setIsShortLinkError(true);
        setShortLinkError('Short link has been taken.');
      } else {
        setIsShortLinkError(false);
        setShortLinkError('');
      }
    }
    if (trimmedDestination === '') {
      setIsDestinationError(true);
      setDestinationError('Destination must be filled.');
    } else {
      if (!checkValidDestination(trimmedDestination)) {
        setIsDestinationError(true);
        setDestinationError('Destination should be a valid url.');
      } else {
        setIsDestinationError(false);
        setDestinationError('');
      }
    }

    let isValid =
      checkValidPathname(trimmedPathname) &&
      isAvailable &&
      trimmedDestination !== '' &&
      checkValidDestination(trimmedDestination);
    if (isValid) {
      setIsShortLinkError(false);
      setShortLinkError('');
      setIsDestinationError(false);
      setDestinationError('');
      hideModal();
      let trimmedDestination = destination.trim();
      let pathname =
        originalPathname === '' ? generateRandomPathname() : originalPathname;
      let id = Math.random().toString();
      newCreateFunc(id, pathname, trimmedDestination);
    } else {
      return;
    }
  };

  let onChangeTextShortLink = (text: string) => {
    if (originalPathname.length + text.length > 30) {
      let textLength = originalPathname.length - 30;
      let slicedText = text.slice(0, originalPathname.length - textLength);
      set({ ...selectedData, originalPathname: slicedText });
    } else {
      set({ ...selectedData, originalPathname: text });
    }
  };

  let onChangeTextDestination = (text: string) => {
    let splittedText = text.split('://');
    let regEx = /https|http/g;
    if (splittedText.length <= 1) {
      set({
        ...selectedData,
        destination: text,
      });
    } else {
      if (splittedText[0] === 'https' || splittedText[0] === 'http') {
        setPrefix(`${splittedText[0]}://`);
        let securedDestination = splittedText.join('').replace(regEx, '');
        set({
          ...selectedData,
          destination: securedDestination,
        });
      } else {
        let securedDestination = splittedText.join('').replace(regEx, '');
        set({
          ...selectedData,
          destination: securedDestination,
        });
      }
    }
  };

  return (
    <Card style={{ width: '50%' }}>
      <Card.Header style={{ justifyContent: 'space-between' }}>
        <Text weight="bold" size="medium">
          Create Short Link
        </Text>
      </Card.Header>
      <Card.Content style={styles.formContent}>
        <TextInput
          label="Short Link"
          value={originalPathname as string}
          groupText
          optionsValue={['kfox.io/']}
          textInputContainerStyle={{ borderWidth: 1 }}
          containerStyle={styles.shortLink}
          onChangeText={onChangeTextShortLink}
          isError={isShortLinkError}
          errorMessage={shortLinkError}
          maxInputLimit={30}
        />
        <TextInput
          label="Destination"
          value={destination as string}
          textInputContainerStyle={{ borderWidth: 1 }}
          optionsValue={GROUP_TEXT}
          containerStyle={styles.destination}
          onChangeText={onChangeTextDestination}
          isError={isDestinationError}
          errorMessage={destinationError}
          selectedValue={prefix}
          groupText
          onOptionPress={setPrefix}
          containerStyleDropdown={styles.prefix}
        />
      </Card.Content>
      <Card.Footer>
        <Fragment>
          <View style={styles.formFooter}>
            <Button mode="text" onPress={handleCancelBtn}>
              Cancel
            </Button>
            <Button onPress={handleCreateLink} style={styles.button}>
              Create Link
            </Button>
          </View>
        </Fragment>
      </Card.Footer>
    </Card>
  );
}

const styles = StyleSheet.create({
  formContent: { flexDirection: 'row', marginRight: 16 },
  shortLink: { marginRight: 8, width: '50%' },
  destination: {
    width: '50%',
    marginLeft: 8,
  },
  prefix: {
    width: '98%',
    marginLeft: 5,
    borderRightWidth: 1,
    borderLeftWidth: 1,
  },
  button: {
    width: 120,
  },
  formFooter: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  deleteButton: { marginLeft: -10 },
});
