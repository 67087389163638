import { ShortLinkData } from '../components/Table/types';
import React, { createContext, Component } from 'react';
import { GROUP_TEXT } from '../constants/grouptextdropdown';

type SelectedData = {
  isEdit: boolean;
  data: ShortLinkData;
  prefix: string;
};

export type SelectedDataContext = {
  isEdit: boolean;
  data: ShortLinkData;
  prefix: string;
  set: (data: ShortLinkData) => void;
  clear: () => void;
  setPrefix: (prefix: string) => void;
};

const DEFAULT_DATA = {
  id: '',
  originalPathname: '',
  destination: '',
  createdAt: '',
};

export const DataContext = createContext<SelectedDataContext>({
  isEdit: false,
  data: DEFAULT_DATA,
  prefix: GROUP_TEXT[0],
  set: () => {},
  clear: () => {},
  setPrefix: () => {},
});

export class SelectedDataProvider extends Component {
  state: SelectedData = {
    isEdit: false,
    data: DEFAULT_DATA,
    prefix: GROUP_TEXT[0],
  };
  render(): React.ReactElement<React.ReactNodeArray> {
    let { isEdit, data, prefix } = this.state;
    return (
      <DataContext.Provider
        value={{
          prefix,
          isEdit,
          data,
          set: this._onContextChange,
          clear: this._onClearContext,
          setPrefix: this._setPrefix,
        }}
      >
        {this.props.children}
      </DataContext.Provider>
    );
  }

  _onContextChange = (data: ShortLinkData) => {
    let isEdit = !!data.id;
    this.setState({
      isEdit,
      data: data,
    });
  };

  _onClearContext = () => {
    this.setState({
      isEdit: false,
      data: DEFAULT_DATA,
      prefix: GROUP_TEXT[0],
    });
  };

  _setPrefix = (prefix: string) => {
    this.setState({ prefix });
  };
}
