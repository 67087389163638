import React from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { Button as BaseButton, ButtonProps } from 'react-native-paper';
import { MaterialIcons as Icon } from '@expo/vector-icons';

import { WHITE, BLACK, ORANGE, GREY } from '../constants/colors';
import { ICON_SIZE } from '../constants/button';
import { Text } from '../core-ui';

type Props = ButtonProps & {
  textColor?: string;
  isLoading?: boolean;
};
type ModeType = 'text' | 'outlined' | 'contained';

function Button(props: Props) {
  let {
    style,
    contentStyle,
    textColor,
    disabled,
    mode = 'contained' as ModeType,
    icon,
    color = ORANGE,
    children,
    isLoading,
    ...otherProps
  } = props;

  let fontColor = disabled
    ? mode === 'contained'
      ? WHITE
      : GREY
    : textColor
    ? textColor
    : mode === 'contained'
    ? WHITE
    : BLACK;

  return (
    <BaseButton
      style={[styles.buttonContainer, style]}
      contentStyle={[styles.defaultStyle, contentStyle]}
      mode={mode}
      disabled={disabled || isLoading}
      color={mode === 'contained' ? color : textColor}
      {...otherProps}
    >
      {isLoading ? (
        <ActivityIndicator color={textColor || WHITE} />
      ) : (
        <View style={styles.container}>
          {icon && (
            <Icon
              name={icon}
              color={fontColor}
              size={ICON_SIZE}
              style={styles.icon}
            />
          )}
          <Text color={fontColor} weight="bold" size="small">
            {children}
          </Text>
        </View>
      )}
    </BaseButton>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginHorizontal: 4,
    justifyContent: 'center',
    maxHeight: 42,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  defaultStyle: {
    height: 42,
    justifyContent: 'center',
  },
  icon: {
    marginRight: 8,
  },
});

export default Button;
