import React, { Component, Fragment, createContext } from 'react';
import { Portal, Snackbar as BaseSnackbar } from 'react-native-paper';
import { StyleSheet } from 'react-native';

type State = {
  duration: number;
  content: string;
  visible: boolean;
};

type SnackbarContextValue = {
  duration: number;
  content: string;
  visible: boolean;
  show: (content: string, duration: number) => void;
};
export let GlobalSnackbar = createContext<SnackbarContextValue>({
  duration: 0,
  content: '',
  visible: false,
  show: () => {},
});

export default class GlobalSnackbarProvider extends Component<{}, State> {
  state: State = {
    duration: 0,
    content: '',
    visible: false,
  };
  render() {
    let { children } = this.props;
    let { duration, content, visible } = this.state;
    return (
      <Fragment>
        <GlobalSnackbar.Provider
          value={{
            visible,
            content,
            duration,
            show: this._showSnackbar,
          }}
        >
          {children}
        </GlobalSnackbar.Provider>
        <Portal>
          <BaseSnackbar
            style={styles.snackbar}
            visible={visible}
            onDismiss={this._onDismiss}
            action={{
              label: '✕',
              onPress: this._onDismiss,
            }}
            duration={duration}
          >
            {content}
          </BaseSnackbar>
        </Portal>
      </Fragment>
    );
  }
  _onDismiss = () => {
    this.setState({ visible: false, content: '', duration: 0 });
  };

  _showSnackbar = (content: string, duration: number) => {
    this.setState({
      visible: true,
      content,
      duration,
    });
  };
}

const styles = StyleSheet.create({
  snackbar: { width: '75%', alignSelf: 'center', marginBottom: 22 },
});
